// SCSS
import "../scss/newsroom.scss";

/**
 * Filter Ajax Load More
 */
$("#alm-filter-nav li").eq(0).addClass("active"); // Set the initial button active state

// Nav btn click event
$("#alm-filter-nav li a").on("click", function (e) {
    e.preventDefault();
    const el = $(this); // Our selected element

    if (!el.hasClass("active")) {
        // Check for active and !alm_is_animating
        // alm_is_animating = true;
        el.parent().addClass("active").siblings("li").removeClass("active"); // Add active state

        const data = el.data(); // Get data values from selected menu item
        const transition = "slide"; // 'slide' | 'fade' | null
        const speed = "300"; // in milliseconds

        ($.fn as any).almFilter(transition, speed, data); // eslint-disable-line
    }
});

async function loadRecentNews() {
    const recentNews = document.querySelector(".js-recent-news");
    if (recentNews) {
        const options = {
            root: null,
            rootMargin: "50px",
            threshold: 0.1,
        };

        if (!("IntersectionObserver" in window)) {
            await import(/* webpackChunkName: "intersection-observer" */ "intersection-observer");
        }

        const sectionWatcher = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const script = document.createElement("script");
                    script.src = "https://cdn.hypemarks.com/pages/a5b5e5.js";
                    script.defer = true;
                    document.head.appendChild(script);
                    observer.disconnect();
                }
            });
        }, options);

        sectionWatcher.observe(recentNews as HTMLElement);
    }
}

loadRecentNews();
